import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import FloatingPanel from '../floating-panel';
import { useMatchBarNew } from '../../hooks';
import MasterSearch from '../master-search';
import FilterRightPanel from '../filter-right-panel';
import { defaultMatchBarState } from '../master-search/utils';
import { convertToTree } from '../../utils';

const MasterFilter = ({
    value,
    onChange,
    masterSearchWithFilterBox = true,
    withMasterSearch = true,
    withRightPanelFilters = false,
    withTagSelector = false,
    visibileParameters,
    tagSelectorVisibleParameters = {},
    matchBarHookArgs,
    count,
    showClearAll = false,
    onClearAll,
    fields = [],
    defaultValue,
    parameterSettings,
    options = {},
    otherMatchBarFields = [],
    ...props
}) => {
    const isMounted = useRef(false);

    const [showFloatingPanel, setShowFloatingPanel] = useState(false); // show floating panel
    const [rightPanelData, setRightPanelData] = useState();
    const [matchbarOutput, setMatchbarOutput] = useState(defaultMatchBarState)

    const { options: matchBarOptions = {}, getMatchBarOutput, setMatchBarData } = useMatchBarNew({ visibileParameters, otherFields: otherMatchBarFields, parameterSettings, ...matchBarHookArgs })

    const hideFloatingPanel = useCallback(() => {
        setShowFloatingPanel(false);
    }, []);

    const onMatchBarClick = useCallback(() => {
        if (withRightPanelFilters) {
            if (showFloatingPanel) {
                hideFloatingPanel()
            } else {
                setRightPanelData({ type: "filter" })
                setShowFloatingPanel(true)
            }
        }
    }, [withRightPanelFilters, showFloatingPanel, hideFloatingPanel])

    const allOptions = useMemo(() => ({ ...matchBarOptions, ...options }), [matchBarOptions, options])

    const tagSelectorOptions = useMemo(() => otherMatchBarFields.filter(fieldData => tagSelectorVisibleParameters[fieldData.id]).map(fieldData => {
        let options = allOptions[fieldData.id]
        switch (fieldData.type) {
            case "multiSelectionWithTree":
                options = convertToTree(options)
                break;
            default:
                break;
        }
        return { ...fieldData, options }
    }), [tagSelectorVisibleParameters, otherMatchBarFields, allOptions])

    const updateMatchBarData = useCallback((updatedMatchbarOutput) => {
        setMatchbarOutput(updatedMatchbarOutput)
        setMatchBarData(updatedMatchbarOutput)
        onChange && onChange(updatedMatchbarOutput, getMatchBarOutput(updatedMatchbarOutput, true).backendFormatOutput)
    }, [setMatchBarData, getMatchBarOutput, onChange])

    // on change of matchbar
    const handleChanges = useCallback((changedData) => {
        const updatedMatchbarOutput = { ...matchbarOutput, ...changedData };
        updateMatchBarData(updatedMatchbarOutput)
    }, [updateMatchBarData, matchbarOutput])

    useEffect(() => {
        if (!isMounted.current) {
            isMounted.current = true;
            if (defaultValue) {
                setMatchbarOutput(defaultValue)
                setMatchBarData(defaultValue)
            }
        }
        if (value) {
            setMatchbarOutput(value)
            setMatchBarData(value)
        }
    }, [value, defaultValue, setMatchBarData])

    return (
        <>
            {withMasterSearch && (
                <MasterSearch
                    visibileParameters={visibileParameters}
                    options={allOptions}
                    onChange={handleChanges}
                    value={matchbarOutput}
                    debounceTime={0}
                    count={count}
                    showClearAll={showClearAll}
                    onClearAll={onClearAll}
                    onClick={onMatchBarClick}
                    withFilterBox={masterSearchWithFilterBox}
                    parameterSettings={parameterSettings}
                    otherFields={otherMatchBarFields}
                    {...props}
                />
            )}
            {withTagSelector && (
                <FilterRightPanel fields={fields} tagSelectorOptions={tagSelectorOptions} value={matchbarOutput} onChange={handleChanges} />
            )}
            <FloatingPanel
                id={rightPanelData?.type}
                direction="right"
                open={showFloatingPanel}
                title="Match"
                withoutHeaderIcon
                onClose={hideFloatingPanel}
            >
                {(showFloatingPanel && (rightPanelData?.type === "filter")) && (<FilterRightPanel fields={fields} tagSelectorOptions={tagSelectorOptions} value={matchbarOutput} onChange={handleChanges} />)}
            </FloatingPanel>
        </>
    )
}

export default MasterFilter