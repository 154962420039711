import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import mixpanel from "mixpanel-browser";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
import App from "./App";
import { RecoilRoot } from "recoil";

// TODO "npx @sentry/wizard@latest -i sourcemaps" need to run this to enable source mapping when we upgrade NODE to grater then 14
process.env.REACT_APP_ENV === "production" &&
  Sentry.init({
    dsn: "https://4f77c54fef887fcbef5a4069c884dd45@o4506455608393728.ingest.sentry.io/4506460359819264",
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          /^https:\/\/qa\.app\.bimmatch\.com/,
          /^https:\/\/app\.bimmatch\.com/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

(process.env.REACT_APP_ENV === "production" || process.env.REACT_APP_ENV === "qa") &&
  process.env.REACT_APP_TRACKING_ID &&
  mixpanel.init(process.env.REACT_APP_TRACKING_ID, {
    enable_collect_everything: true,
  });

ReactDOM.render(
  <RecoilRoot>
    {" "}
    <App />{" "}
  </RecoilRoot>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
process.env.REACT_APP_ENV === "production" && serviceWorker.register();
