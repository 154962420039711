import { useLazyQuery } from "@apollo/client";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import {
  allProductPropertiesState,
  authState,
  propertyValueOptionsState,
} from "../atoms";
import {
  GET_DISPLAY_SECTIONS,
  GET_PRODUCT_PROPERTIES,
  GET_PRODUCT_PROPERTY_VARIANTS,
} from "../graphqlQueries";
import {
  bimmatchOrgID,
  cmsStaticPropertyIds,
  deepClone,
  getAllKeysWithValueInObj,
  getUnitConversion,
  makeTreeFromCategorisedOptions,
} from "../utils";
import uniclass from "../json/uniclassTree.json";


const regexExpUUID = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/

const initialPropertiesData = {
  productProperties: [],
  productPropertyDisplaySections: [],
  propertyValueOptions: [],
  productPropertyVariants: [],
};

export default function usePropertiesNew(orgID) {

  // local states
  const [filteredPropertiesData, setFilteredPropertiesData] = useState(initialPropertiesData);
  const [publicPropertiesData, setPublicPropertiesData] = useState(initialPropertiesData);

  // global states
  const propertyValueOptions = useRecoilValue(propertyValueOptionsState);
  const authData = useAtomValue(authState);
  const [allPropertiesData, setAllPropertiesData] = useAtom(allProductPropertiesState)

  // ---API call for all the fields
  const [fetchProperties, { data: productPropertiesData }] = useLazyQuery(GET_PRODUCT_PROPERTIES)

  // ---API call for all the sections required for the field and main form section
  const [fetchDisplaySections, { data: displaySectionsData }] = useLazyQuery(GET_DISPLAY_SECTIONS);

  // ---API call to get variants of fields/properties
  const [fetchproductPropertyVariants, { data: productPropertyVariantsData }] = useLazyQuery(GET_PRODUCT_PROPERTY_VARIANTS);

  useEffect(() => {
    const data = {
      productProperties: productPropertiesData?.getProductProperties,
      productPropertyDisplaySections: displaySectionsData?.getDisplaySections,
      productPropertyVariants: productPropertyVariantsData?.getProductPropertyVariants,
      propertyValueOptions: propertyValueOptions,
    };
    if (
      data?.productProperties &&
      data?.productPropertyDisplaySections &&
      data?.propertyValueOptions &&
      (authData?.user ? data?.productPropertyVariants : true) &&
      !allPropertiesData
    ) {
      setAllPropertiesData(data);
    }
  }, [
    displaySectionsData,
    productPropertiesData,
    propertyValueOptions,
    productPropertyVariantsData,
    allPropertiesData,
    setAllPropertiesData,
    authData,
  ]);

  const commonFilterPropertyObj = useCallback((filterProperty) => {
    const filteredPropertyObj = {}

    const keysToApplyFilterOn = ["productProperties", "productPropertyDisplaySections", "propertyValueOptions", "productPropertyVariants"]

    keysToApplyFilterOn.forEach(keyForFilter => {
      filteredPropertyObj[keyForFilter] = []
      if (allPropertiesData?.[keyForFilter] && allPropertiesData[keyForFilter]?.length) {
        filteredPropertyObj[keyForFilter] = allPropertiesData[keyForFilter].filter((property) => filterProperty(property))
      }
    })

    return filteredPropertyObj
  }, [allPropertiesData]);

  // saving the propeorties that belongs to org or bimmatch
  const updatefilterPropertiesData = useCallback(() => {
    if (orgID) {
      function filterProperty(property) {
        return (
          (property.organizationId === orgID) ||
          (property.organizationId === bimmatchOrgID)
        )
      }
      setFilteredPropertiesData(commonFilterPropertyObj(filterProperty));
    }
  }, [orgID, commonFilterPropertyObj]);


  // to save/update the properties that are only of bimmatch
  const updatePublicPropertiesData = useCallback(() => {
    function filterProperty(property) {
      return (property.organizationId === bimmatchOrgID)
    }

    setPublicPropertiesData(commonFilterPropertyObj(filterProperty));
  }, [commonFilterPropertyObj]);

  const fetchPropertiesData = useCallback(() => {
    fetchProperties();
    fetchDisplaySections();
    if (authData?.user && !allPropertiesData?.productPropertyVariants) {
      fetchproductPropertyVariants();
    }
  }, [
    authData,
    fetchProperties,
    fetchDisplaySections,
    fetchproductPropertyVariants,
    allPropertiesData,
  ]);

  const updateAllPropertiesData = useCallback(() => {
    if (!(allPropertiesData &&
      allPropertiesData?.productProperties &&
      allPropertiesData?.productPropertyDisplaySections &&
      allPropertiesData?.propertyValueOptions &&
      (authData?.user ? allPropertiesData?.productPropertyVariants : true))
    ) {
      fetchPropertiesData();
    }
  }, [fetchPropertiesData, allPropertiesData, authData]);

  useEffect(() => {
    updateAllPropertiesData();
  }, [updateAllPropertiesData]);

  useEffect(() => {
    updatefilterPropertiesData();
  }, [updatefilterPropertiesData]);

  useEffect(() => {
    updatePublicPropertiesData();
  }, [updatePublicPropertiesData]);

  //to get options list in simple format (not tree or categorised)
  const getOptionsByPropertyId = useCallback(({
    propertyId,
    excludeBimmatchProperty = false,
    excludePropertyIds,
    removeExtraFields,
    organizationId = orgID,
  } = {}) => {

    function filterProperty(property) {
      return (
        property.organizationId === organizationId ||
        property.organizationId === bimmatchOrgID
      );
    }

    const propertiesData = commonFilterPropertyObj(filterProperty);

    let allOptions = [];
    if (propertiesData?.propertyValueOptions) {
      const propertyValueOptionsCopy = deepClone(propertiesData?.propertyValueOptions);
      allOptions = propertyValueOptionsCopy.filter((optionData) => {
        const toIncludeOption = (!excludeBimmatchProperty ? optionData.propertyIds.includes(propertyId) : (optionData.propertyIds.includes(propertyId) && (optionData.organizationId !== bimmatchOrgID)))
        // if found then deleting keys accprding to passed argument conditions
        if (toIncludeOption && excludePropertyIds)
          delete optionData.propertyIds;
        if (toIncludeOption && removeExtraFields) {
          const extraFields = ["additionalColumnValues", "displayOrder", "organizationId"]

          extraFields.forEach((fieldName) => {
            delete optionData[fieldName];
          });
        }
        return toIncludeOption;
      });
    }
    return allOptions;
  }, [commonFilterPropertyObj, orgID]);


  const addCarbonEmmission = useCallback((optionData) => {
    if (optionData?.metadataObj) {
      const { carbonEmissionPerM3, carbonEmissionPerM2, carbonEmissionPerM, carbonEmissionPerKg } = optionData.metadataObj;
      if (carbonEmissionPerM3 || carbonEmissionPerM2 || carbonEmissionPerM || carbonEmissionPerKg) {
        let value, unit;
        if (carbonEmissionPerM3) {
          value = carbonEmissionPerM3;
          unit = "m3";
        } else if (carbonEmissionPerM2) {
          value = carbonEmissionPerM2;
          unit = "m2";
        } else if (carbonEmissionPerM) {
          value = carbonEmissionPerM;
          unit = "m";
        } else if (carbonEmissionPerKg) {
          value = carbonEmissionPerKg;
          unit = "kg";
        }
        optionData.name = optionData.displayName;
        optionData.displayName = `${optionData.displayName} | ${value} kg CO2e / ${unit}`;
      }
    }
  }, [])

  const createProductForm = useCallback(
    ({
      categoryIds = [],
      propertiesData = filteredPropertiesData,
      removeSections = [],
      mainCategory = "",
    }) => {
      let formMappedData = [];
      const {
        productProperties = filteredPropertiesData?.productProperties,
        productPropertyDisplaySections = filteredPropertiesData?.productPropertyDisplaySections,
        propertyValueOptions = filteredPropertiesData?.propertyValueOptions,
        productPropertyVariants = filteredPropertiesData?.productPropertyVariants,
      } = propertiesData;

      if (productProperties && productPropertyDisplaySections && propertyValueOptions && categoryIds ) {
        const formFieldsDataCopy = deepClone(productProperties);
        const fieldsWithSelectedCategories = formFieldsDataCopy.filter(
          (fieldData) => (categoryIds?.length ? (categoryIds.some((categoryId) => fieldData.categoryIds.includes(categoryId)) || !fieldData.categoryIds.length) : true)
        );

        // updating option in fields with type select---------------------------
        const selectTypeFields = fieldsWithSelectedCategories.filter(
          (fieldData) => fieldData.type === "select"
        );

        selectTypeFields.forEach((fieldData) => {
          const fieldId = fieldData.id;
          const formOptionsDataCopy = deepClone(propertyValueOptions);
          const formSectionsDataCopy = deepClone(productPropertyDisplaySections);

          // updating option in fields with type multiselect with section
          if (
            fieldData.subType === "multiSelectionWithSection" ||
            fieldData.subType === "multiSelectionWithTree"
          ) {
            // filtering sections for the field
            const filteredSectionsForField = formSectionsDataCopy.filter((sectiondata) => {
                const toIncludeSection = ((sectiondata.type === "multipleSelectionGroup") && sectiondata.propertyIds.includes(fieldId))
                if (toIncludeSection) {
                  // if found then deleting propertyIds key from section
                  delete sectiondata.propertyIds;

                  // filtering options for the above filtered section
                  const optionForSectionForField = formOptionsDataCopy.filter(
                    (optionD) => {
                      const toIncludeOption =
                        optionD.displaySection === sectiondata.id;

                      // if found then deleting propertyIds key from option
                      if (toIncludeOption) {
                        delete optionD.propertyIds;

                        // adding relevant carbon emission to the name
                        addCarbonEmmission(optionD)
                      }
                      return toIncludeOption;
                    }
                  );

                  if (optionForSectionForField.length) {
                    sectiondata[
                      fieldData.subType !== "multiSelectionWithTree"
                        ? "buckets"
                        : "children"
                    ] = optionForSectionForField;
                  }
                }
                return toIncludeSection;
              }
            );

            if (filteredSectionsForField.length) {
              fieldData.sections = filteredSectionsForField;

              // making tree structure from the categorised options
              if (fieldData.subType === "multiSelectionWithTree") {
                fieldData.sections = makeTreeFromCategorisedOptions(
                  filteredSectionsForField
                );
              }
            }

            // for uniclass field inserting the uniclass json that we keep in client side
            if (fieldData.id === cmsStaticPropertyIds.uniclass) {
              fieldData.sections = uniclass;
            }
          } else {
            const filteredOptions = getOptionsByPropertyId({
              propertyId: fieldId,
              excludePropertyIds: true,
            });
            if (filteredOptions.length) fieldData.options = filteredOptions;
          }
        });

        // -------------------------------------xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx

        // filtering sections for the main form
        const pageSections = productPropertyDisplaySections.filter((sectionData) => 
        (sectionData.type === "productPageSection") 
        && !removeSections.includes(sectionData.id) 
        && (!sectionData?.categoryIds?.length || categoryIds.some((categoryId) => sectionData?.categoryIds.includes(categoryId)))
        );

        const pageSectionsWithFields = [];

        // updating section data with fields for each section
        if (pageSections.length) {
          pageSections.forEach((eachSection, index) => {
            let fields =  fieldsWithSelectedCategories.filter((fieldData) => fieldData.displaySection === eachSection.id)

            // checking if variant exists for any field then replacing that field with variant if found
            fields.forEach((field, fieldIndex) => {
              // acc to unit type changing label
              if (field.label.includes("sqm")) {
                const constvertedLabelUnit = getUnitConversion("sqm").unit;
                field.label = field.label.replace("sqm", constvertedLabelUnit);
              }

              if (productPropertyVariants && productPropertyVariants?.length) {
                const variantOfField = productPropertyVariants.find((variant) => ((variant.productPropertyId === field.id) && variant.categoryIds.includes(mainCategory)));

                //---- variant found
                if (variantOfField) {
                  const variantOfFieldCopy = deepClone(variantOfField);
                  delete variantOfFieldCopy.productPropertyId;
                  const variantOfFieldCopyWithValidFields = getAllKeysWithValueInObj(variantOfFieldCopy);

                  // replacing original field data with variant data
                  fields[fieldIndex] = {
                    ...field,
                    ...variantOfFieldCopyWithValidFields,
                    id: field.id,
                    organizationId: field.organizationId,
                  };
                } else {
                  fields[fieldIndex] = field;
                }
                //---- variant functionality ends---
              }
            });

            // if fields found
            if (fields?.length) {
              pageSectionsWithFields.push({
                ...eachSection,
                fields,
              });
            }
          });
        }
        formMappedData = pageSectionsWithFields 
      }

      return formMappedData;
    }, [filteredPropertiesData, getOptionsByPropertyId, addCarbonEmmission]);

  //get options of fields in same format as found in the product form... supported for all select types
  const getproductFormOptionsByPropertyId = useCallback((propertyId = "") => {
      const productForm = createProductForm({});
      if (productForm?.length) {
        // extracting all the fields from the sections inside one array
        const productFormFields = productForm
          .map((section) => section.fields)
          .flat();

        // getting the select type field of id matching the passed argument propertyId
        const requiredSelectTypeField = productFormFields.find(
          (fieldData) =>
            fieldData.type === "select" && fieldData.id === propertyId
        );

        // getting the options from the above field
        const requeredFieldOptions =
          requiredSelectTypeField?.options ||
          requiredSelectTypeField?.sections ||
          [];

        return requeredFieldOptions;
      }
    }, [createProductForm]);

  const filterData = useCallback((toFilterProperties, { allData = allPropertiesData, excludeDisplaySectionIds = [] } = {}) => {
    let outputFilterData = [];

    const getPropertyValue = (id) => (allData?.propertyValueOptions || []).find((value) => value.id === id);

    (allData?.productProperties || []).forEach((propertyData) => {
      // finding property from the list
      const matchedProperty = toFilterProperties.find((resProperty) => resProperty.id === propertyData.id)

      if (matchedProperty) {
        const singlePropertyDataCopy = deepClone(propertyData);
        singlePropertyDataCopy.property = matchedProperty

        const displaySection = allData.productPropertyDisplaySections.find((pds) => pds.id === singlePropertyDataCopy.displaySection);
        if (displaySection && excludeDisplaySectionIds.includes(displaySection.id)) {
          return;
        }

        singlePropertyDataCopy.displaySection = displaySection;
        const values = [];
        if (regexExpUUID.test(singlePropertyDataCopy?.property?.value)) {
          const value = getPropertyValue(singlePropertyDataCopy.property.value)
          if (value) {
            values.push(value);
          }
        }
        if (singlePropertyDataCopy?.property?.values?.length) {
          singlePropertyDataCopy.property.values.forEach((valueId) => {
            if (regexExpUUID.test(valueId)) {
              const value = getPropertyValue(valueId)
              if (value) {
                values.push(value);
              }
            }
          });
        }

        singlePropertyDataCopy.values = values;
        singlePropertyDataCopy.values = singlePropertyDataCopy.values.map((valueData) => {
          const value = valueData ? deepClone(valueData) : {};
          if (regexExpUUID.test(value.displaySection)) {
            value.displaySection = allData.productPropertyDisplaySections.find((pds) => pds.id === value.displaySection);
          }
          return value;
        });

        outputFilterData.push(singlePropertyDataCopy);
      }
    });
    return outputFilterData;
  }, [allPropertiesData]);

  // to extract details about property like section data for select
  const getMatchbarData = useCallback((categoryIds = [], selectedPropertyKeys = [], removeDisplaySections = []) => {
    const propertiesData = orgID ? filteredPropertiesData : publicPropertiesData

    const optionsForProperties = [] // bucket 
    const optionsForPropertyValues = [] // bucket

    function commonFilter(allowedCategories) {
      return allowedCategories?.length ? allowedCategories.some((catId) => categoryIds.includes(catId)) : true
    }

    // finding matching properties according to categories
    const filteredProperties = deepClone(propertiesData?.productProperties).filter((property) => {
      return commonFilter(property?.categoryIds)
    });

    // finding display sections of above matched properties
    const displaySections = deepClone(propertiesData?.productPropertyDisplaySections).filter((sectionData) => {

      // removing desired sections
      if (removeDisplaySections.includes(sectionData.id)) {
        return false;
      }
      return commonFilter(sectionData?.categoryIds)
    });

    // options of the selected paramaters
    const propertyValues = propertiesData?.propertyValueOptions.filter((propertyValue) => {
      return commonFilter(propertyValue?.categoryIds)
    });

    displaySections.filter((sectionData) => sectionData.type === "productPageSection")
      .forEach((sectionData) => {
        const sectionFilteredProperties = filteredProperties.filter((propertyData) => propertyData.displaySection === sectionData.id).map((propertyData) => ({ ...propertyData, displayName: propertyData?.label || "" }))
        optionsForProperties.push({
          ...sectionData,
          buckets: sectionFilteredProperties
        })

        const sectionFilteredPropertiesAccToSelectedPropertykeys = sectionFilteredProperties.filter(propertyData => selectedPropertyKeys.includes(propertyData.id))

        if (sectionFilteredPropertiesAccToSelectedPropertykeys.length) {
          sectionFilteredPropertiesAccToSelectedPropertykeys.forEach(propertyData => {
            const filteredValues = deepClone(propertyValues).filter((valueData) => {
              const toInclude = valueData.propertyIds.includes(propertyData.id)

              // handling scenario of duplicate option ids for diff property section
              if (toInclude) {
                valueData.id = `${valueData.id}:${propertyData.id}`
              }

              return toInclude
            });

            const optionDataForValues = {
              ...propertyData,
              buckets: filteredValues
            }

            optionsForPropertyValues.push(optionDataForValues)
          })
        }
      });
    return { optionsForProperties, optionsForPropertyValues }
  },
    [filteredPropertiesData, publicPropertiesData, orgID]
  );

  const getDataById = useCallback((optionsArray) => {
    return (optionId) => optionsArray.find(optionData => optionData.id === optionId) || {}
  }, [])

  return {
    filteredPropertiesData,
    allPropertiesData,
    createProductForm,
    filterData,
    getMatchbarData,
    getPropertyDataById: getDataById(allPropertiesData ? allPropertiesData?.productProperties : []),
    getPropertyValueOptionDataById: getDataById(allPropertiesData ? allPropertiesData?.propertyValueOptions : []),
    updateStoredPropertiesData: setAllPropertiesData,
    refetchPropertiesData: fetchPropertiesData,
    storedPropertiesData: allPropertiesData,
    getOptionsByPropertyId,
    getproductFormOptionsByPropertyId,
  };
}
