import React from 'react';
import Chip from '../chip';
import { combineNames } from '../../utils';

function getCategoryImg(data) {
    try {
      const src =
        data?.image ||
        require(`../../images/icon/${data?.id || 'truck-blue'}.svg`)
      return <img className='category-icon' src={src} alt='category-icon' />
    } catch (error) {
      return <></>
    }
  }

// --Not a reusable component--
const CategoryType = ({ searchData, filterData, currentActiveCategoryTab, totalSelectedData, onDeselectOption, searchInput, onSelectOption, onClickCategoryHead }) => {
    return (
      <>
        {searchData.map((categoryData, tabIndex) => (
          <div key={categoryData?.id} className='category-container'>
            <div
              className={`category-main ${(!categoryData?.buckets?.length || !filterData(categoryData.buckets).length) ? 'category-main-no-data': 'category-main-with-data'} 
              ${(!!categoryData?.buckets?.length && currentActiveCategoryTab !== tabIndex + 1) && 'category-main-close' }`}
            >
              <div
                className='category-head'
                onClick={() => onClickCategoryHead(tabIndex)}
              >
                {/* <img
                  className="category-icon"
                  src={`../../images/icon/${categoryData?.id || "truck-blue"
                    }.svg`}
                  alt="category-icon"
                /> */}
                {getCategoryImg(categoryData)}
                <h4 className='category-name'>
                  {combineNames(categoryData) || categoryData?.id}
                </h4>
                {!!totalSelectedData[categoryData?.id]?.length && (
                  <div className='category-data-close'>
                    {totalSelectedData[categoryData?.id]?.map(
                      headChipData => (
                        <Chip
                          key={headChipData.id + '-head-chip'}
                          name={combineNames(headChipData)}
                          withCross={true}
                          onClickCancel={event =>
                            onDeselectOption(
                              event,
                              categoryData?.id,
                              headChipData
                            )
                          }
                        />
                      )
                    )}
                  </div>
                )}

                {searchInput &&
                  !!categoryData?.buckets?.length &&
                  !filterData(categoryData?.buckets).length && (
                  <div className='no-cateogory-data'>No search results</div>
                )}
              </div>
              {!!categoryData?.buckets?.length &&
                (searchInput || currentActiveCategoryTab === tabIndex + 1) && (
                <div className='category-data-open-container'>
                  <div className='category-data-open'>
                    {!!totalSelectedData[categoryData?.id]?.length &&
                        filterData(
                          totalSelectedData[categoryData?.id]
                        ).map(headChipData => (
                          <Chip
                            key={headChipData?.id + '-black-head-data'}
                            highlightText={searchInput}
                            name={combineNames(headChipData)}
                            darkMode={true}
                            onClickChip={event =>
                              onDeselectOption(
                                event,
                                categoryData?.id,
                                headChipData
                              )
                            }
                          />
                        ))}

                    {!!categoryData?.buckets?.length &&
                        filterData(categoryData?.buckets)
                          .filter(bucketFilterData =>
                            totalSelectedData[categoryData?.id]?.length ? 
                            !totalSelectedData[categoryData?.id]
                                .map(selectedChipData => selectedChipData.id)
                                .includes(bucketFilterData.id)
                              : true
                          )
                          .map(bucketData => (
                            <Chip
                              key={bucketData?.id}
                              highlightText={searchInput}
                              name={combineNames(bucketData)}
                              onClickChip={() =>
                                onSelectOption(categoryData?.id, bucketData)
                              }
                            />
                          ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </>
    )
  }

  export default CategoryType