import { refreshAutodeskAccessToken } from ".";
import { deleteCookie, getCookie } from "../../utils"
import { getFirebaseToken, onWindowBlurListener, visibilityChangeListner } from "../firebase/firebase";

//0.04152778 is days and is equal to approx 60 mins which is generally expiry time from diff auth channels
// 0.000173611 days = 15 sec (can be used for testing)
export const accessTokenExpiryTime = 0.04152778

// in mins
export const autoLogoutTime = 120

export function commonAuthDeleteCookies() {
    const toDeleteCookies = ['__bauhub_token', "__bauhub_token_ts", '__current_organization', '__user_displayName', '__bauhub_refresh_token', "__login_mode"]
    toDeleteCookies.forEach((cookie) => {
        deleteCookie(cookie)
    })

    commonRemoveAuthEvents()
}

export function commonAuthTracking({ tracking, user, type = "Sign In" }) {
    if (tracking) {
        tracking.people.set({
            $email: user.email.toLowerCase(),
            uid: user.uid,
            $distinct_id: user.uid,
            $name: user.displayName,
            USER_ID: user.displayName,
        });
        tracking.track(type);
    }
}


export async function commonAuthHeaders(headers) {
    const supportedLoginModes = ["autodesk", "google"]

    const logInMode = getCookie("__login_mode");
    let access_token = getCookie("__bauhub_token");
    const refresh_token = getCookie("__bauhub_refresh_token")
    const lang = getCookie("__bauhub_lang");
    const currentOrgId = getCookie("__current_organization");
    const pluginType = getCookie("pluginType")

    const newHeaders = {
        ...headers,
        "Apollo-Require-Preflight": true,
    };

    if (lang) newHeaders["Accept-Language"] = lang;

    if (supportedLoginModes.includes(logInMode)) {

        if (logInMode === "autodesk" && !access_token && refresh_token) {
            access_token = await refreshAutodeskAccessToken()
        }

        if (logInMode === "google" && !access_token) {
            access_token = await getFirebaseToken()
        }

        if (access_token && currentOrgId) newHeaders.organizationId = currentOrgId;

        if (logInMode && access_token) newHeaders.logInMode = logInMode;

        if (pluginType) newHeaders.pluginType = pluginType

        newHeaders.authorization = access_token ? `Bearer ${access_token}` : "";
        return newHeaders
    } else {
        return headers
    }
}

export function commonRemoveAuthEvents() {
    document.removeEventListener("visibilitychange", visibilityChangeListner);
    window.removeEventListener("blur", onWindowBlurListener);
}