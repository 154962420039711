import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import { GET_ORGANIZATION_ITEMS } from '../graphql';
import { prepareDynamicDataFromBackendFacets } from '../utils';
import useGetFacetsData from './useGetFacetsData';

const useOrganizationItems = ({
    variables,
    selectedCategoryIds,
    onDataFetched  // callback for parent to handle data
}) => {
    // states
    const [otherMatchBarOptions, setOtherMatchBarOptions] = useState({});
    const [matchBarDynamicFields, setMatchBarDynamicFields] = useState([]);

    const { categories } = useGetFacetsData();

    const handleFetchedOrgItems = useCallback((orgItemsData) => {
        const { facets = [] } = (orgItemsData?.searchOrganizationObjects) || {};
        const { fields, options } = prepareDynamicDataFromBackendFacets(
            facets,
            categories,
            selectedCategoryIds
        );

        setOtherMatchBarOptions(options);
        setMatchBarDynamicFields(fields);

        // Callback to parent
        onDataFetched && onDataFetched(orgItemsData);
    }, [categories, selectedCategoryIds, onDataFetched]);

    const { loading, data, fetchMore, refetch } = useQuery(
        GET_ORGANIZATION_ITEMS,
        {
            variables,
            onCompleted: handleFetchedOrgItems
        }
    );

    return { loading, data, fetchMore, refetch, otherMatchBarOptions, matchBarDynamicFields };
};

export default useOrganizationItems;