import { gql } from "@apollo/client";
import { countryType, imageType } from "../constants";

export const GET_PROJECT_DETAIL = gql`
  query ($id: ID!) {
    getHouse(id: $id) {
      id
      displayName
      model
      location
      country {
        ${countryType}
      }
      images {
        ${imageType}
      }
      createdBy {
        id
        displayName
        photoUrl
      }
      team {
        id
        photoUrl
        displayName
        email
      }
      bom {
        status
      }
      createdAt
      organizationLibs {
        id
        displayName
      }
      typeOfProject
      matchEngineSettings {
        locations
        standards
      }
      region
      source
      viewDisplayName
      viewGuid
      compoundLayer {
        status
      }
    }
  }
`;

export const GET_BOM_PRODUCTS = gql`
  query ($houseId: String!) {
    getBOMProducts(houseId: $houseId) {
      objectId
      productId
      productName
      paramType
      product {
        id
        displayName
        images {
          ${imageType}
        }
        categories {
          id
          displayName
        }
        brand {
          id
          displayName
        }
        properties {
          id
          value
          values
        }
        sustainabilityRanks {
          environmental
          economic
          social
        }
      }
    }
  }
`;

export const GET_BOM_METADATA = gql`
  query ($houseId: String!) {
    getBOMMetaData(houseId: $houseId) {
      objectid
      name
      childrenLength
      environmental
      economic
      social
      carbonEmission
      price
      nonOrganizationParam
      organizationParam
      fileParam
    }
  }
`;

export const SEARCH = gql`
  query RecommendProducts(
    $id: ID!
    $from: Int
    $size: Int
    $input: SearchInput
  ) {
    recommendProducts(id: $id, from: $from, size: $size, input: $input) {
      hits {
        total
        results {
          id
          displayName
          images {
            ${imageType}
          }
          categories {
            id
            displayName
          }
          brand {
            id
            displayName
          }
          properties {
            id
            value
            values
          }
          sustainabilityRanks {
            environmental
            economic
            social
          }
        }
      }
    }
  }
`;