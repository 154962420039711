import { useResetRecoilState } from "recoil";
import { commonAuthDeleteCookies } from "../common/auth/utils";
import { getFirebaseInstance } from "../common/firebase";
import { getCookie } from "../utils";
import { useResetAtom } from "jotai/utils";
import { allProductPropertiesState, authState, checkUserAuthState, hasCreatedNewUserAuthState, organizationState, planDetailsState, userFeaturesPermissionsState, userState } from "../atoms";
import { useCallback } from "react";


export default function useLogout() {

    //---global states---
    //--recoil
    const resetOrganization = useResetRecoilState(organizationState);
    const resetUser = useResetRecoilState(userState);
    const resetPlanDetails = useResetRecoilState(planDetailsState);

    //--jotai
    const resetAuthState = useResetAtom(authState);
    const resetCheckUserAuth = useResetAtom(checkUserAuthState);
    const resetHasCreatedNewUserAuth = useResetAtom(hasCreatedNewUserAuthState);
    const resetAllProductPropertiesState = useResetAtom(allProductPropertiesState)
    const resetUserFeaturesPermissions = useResetAtom(userFeaturesPermissionsState);

    const logout = useCallback(() => {
        const firebase = getFirebaseInstance();

        const logInMode = getCookie("__login_mode");

        if (logInMode === "google") {
        firebase.auth.signOut();
        }

        commonAuthDeleteCookies()

        localStorage.clear();

        // resetting necessary global states
        resetOrganization();
        resetUser();
        resetPlanDetails();
        resetAuthState();
        resetCheckUserAuth();
        resetHasCreatedNewUserAuth();
        resetAllProductPropertiesState()
        resetUserFeaturesPermissions()

        // if needed add here
        // history.push("/signin")
    }, [resetAllProductPropertiesState, resetAuthState, resetCheckUserAuth, resetHasCreatedNewUserAuth, resetOrganization, resetPlanDetails, resetUser, resetUserFeaturesPermissions])

    return logout
}