import React, { useCallback } from 'react'
import { FormFields, GroupedTagSelector } from '../../components'

const FilterRightPanel = ({ fields, tagSelectorOptions = {}, value, onChange }) => {

    const handleChanges = useCallback((changedData) => {
        onChange && onChange(changedData)
    }, [onChange])

    return (
        <div className='filters'>
            {fields.map(fieldData => (
                <FormFields
                    key={fieldData.fieldName}
                    fieldName={fieldData.fieldName}
                    label={fieldData.label}
                    type={fieldData.type}
                    options={fieldData?.options}
                    validations={fieldData?.validations}
                    placeholder={fieldData.placeholder}
                    onChange={handleChanges}
                    value={value[fieldData.fieldName]}
                />
            ))}

            <GroupedTagSelector
                data={tagSelectorOptions}
                value={value}
                onChange={handleChanges}
                withSearch
                searchPlaceholder='Search filter attribute'
                withHr={!!fields.length}
                title='Search by attributes and values'
            />
        </div>
    )
}

export default FilterRightPanel