import { gql } from "@apollo/client";
import { imageType } from "../constants";

export const GET_JOB_NOTIFICATIONS = gql`
    query GetJobNotifications {
        getJobNotifications {
            id
            organizationId
            status
            type
            metadata {
                itemsFailed
                itemsProcessed
                totalItems
            }
            createdAt
            createdBy
            updatedAt
        }
    }
`;

export const UPDATE_JOBS = gql`
    mutation UpdateJobs($input: [UpdateJobInput]!) {
        updateJobs(input: $input) {
            status
            message
        }
    }
`;

export const GET_ORGANIZATION_ITEMS = gql`
    query SearchOrganizationObjects($from: Int, $size: Int, $organizationIds: [String], $input: SearchOrganizationObjectsInput, $externalId: ID) {
        searchOrganizationObjects(from: $from, size: $size, organizationIds: $organizationIds, input: $input, externalId: $externalId) {
            hits {
                total
                results {
                    id
                    displayName
                    organizationName
                    organizationId
                    organizationPlan
                    mainCategory
                    categories {
                        id
                        displayName
                        parentCategoryId
                    }
                    images {
                        ${imageType}
                    }
                    files {
                        id
                        values {
                            ${imageType}
                        }
                    }
                    properties {
                        id
                        label
                        type
                        subType
                        value
                        values
                    }
                    sustainabilityRanks {
                        environmental
                        economic
                        social
                    }
                    status
                    createdAt
                    createdBy
                    updatedAt
                    updatedBy
                    source
                    houses
                }
            }
            facets {
                id
                label
                displayOrder
                options {
                    id
                    displayName
                }
            }
        }
    }
`;