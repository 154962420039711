import React from 'react';

const ProgressBar = ({
    progress = 0,
    text,
    striped = true,
    animated = true
}) => {
    return (
        <div className="bimmatch-progress-container">
            {text && <div className="progress-text">{text}</div>}
            <div className="progress">
                <div className={`progress-bar ${striped ? 'progress-bar-striped' : ''} ${animated ? 'progress-bar-animated' : ''}`} style={{ width: `${Math.min(100, Math.max(0, progress))}%` }} />
            </div>
        </div>
    );
};

export default ProgressBar;