import React, { useCallback, useEffect, useRef, useState } from "react";
import { fieldValidation } from "../../utils";
import CategorisedSearch from "../categorised-search";
import ErrorText from "../error-text";
import LabelBox from "../label-box";

const Select = ({
  id,
  required = false,
  placeholder = "Type",
  label,
  isError = false,
  errorMessage,
  hideLabel = false,
  options,
  onChangeInput,
  multiple = false,
  type = "select",
  onSelect,
  disable = false,
  fieldName,
  validations,
  showValidationMessage = false,
  value,
  infoPlacement,
  info,
  searchIcon,
  addButtonText='',
  onClickAddButton,
  className,
  showSettings,
  onChangeSettings,
  defaultSettings,
  defaultValue,
  showAppliedSettings,
  onClickSettings,
  settingsValue,
  onDelete,
  showClearAll = false,
  optionsClassName = "",
  optionsShadow = true,
  ...props
}) => {
  const [hasError, setHasError] = useState(isError);
  const [hasErrorMessage, setHasErrorMessage] = useState(errorMessage);
  const settings = useRef(null)
  // fieldValue is being used in case fieldName is passed
  const fieldValue = useRef(null)

  // updating state of on change of error & message
  useEffect(() => {
    setHasError(isError)
  }, [isError])

  useEffect(() => {
    setHasErrorMessage(errorMessage)
  }, [errorMessage])

  useEffect(() => {
    if (defaultValue && !fieldValue.current) {
      const defaultFieldValue = {
        [fieldName]: defaultValue
      }

      if (defaultSettings) defaultFieldValue.settings = defaultSettings
      fieldValue.current = defaultFieldValue
    }
  }, [defaultSettings, defaultValue, fieldName])


  const onSelectChips = useCallback((flag, item, data) => {
      const initialData = (type !== "category" && !multiple ) ? item : data;
      let finalData
      let formattedValue = []
      let formattedValueRaw = []
      
      function toMakeFormattedValues(selectedOption) {
        formattedValue.push(selectedOption.id)
        formattedValueRaw.push({
          id : selectedOption.id,
          displayName: selectedOption.displayName
        })
      }
      if (type !== "category") {
          if (!multiple && initialData) {
            toMakeFormattedValues(initialData)
          } else if (initialData && initialData.length) {
            initialData.forEach(option => {
              toMakeFormattedValues(option)
            });
          } 
      } else if (Object.values(initialData).length) {
        Object.values(initialData).flat().forEach(option => {
          toMakeFormattedValues(option)
        });
      }

      const { isValid, message } = fieldValidation((validations ? { ...validations, type: "select" } : { type: "select" }),
        formattedValue,
        label
      );
       
       if (!validations?.returnName) {
        finalData = (type !== "category" && !multiple) ? formattedValue[0] : formattedValue;
       } else { //---to get selected data in object format
        finalData = (type !== "category" && !multiple) ? formattedValueRaw[0] : formattedValueRaw;
       }


      if (!isValid) {
        if (!hasError) setHasError(true);
        if (message) setHasErrorMessage(message);
      } else {
        if (hasError) setHasError(false);
        if (hasErrorMessage) setHasErrorMessage("");
      }

      if (fieldName) {
        const argData = { [fieldName]: finalData }
        if (settings.current) argData.settings = settings.current
        fieldValue.current = argData
        onSelect && onSelect(argData, item, flag);
      } else {
        //TODO - change the structure of data being send (formattedValue)
        onSelect && onSelect(finalData, item, flag);
      }
    },
    [
      onSelect, fieldName, multiple, type,validations,
      hasError, hasErrorMessage, label, settings
    ]
  );

  const onChangeLabelSettings = useCallback(settingsData => {
    onChangeSettings && onChangeSettings(settingsData)
    settings.current = settingsData
    onSelect && onSelect(fieldValue.current ? { ...fieldValue.current, settings: settingsData } : { [fieldName]: null, settings: settingsData })
  }, [onChangeSettings, fieldValue, onSelect, fieldName])

  return (
    <div
      id={id}
      className={`bimmatch-select ${disable ? "bimmatch-select-disabled unselectable" : ""
        } ${hasError ? "bimmatch-select-error" : ""} ${className ?? ""}`}
    >
      <div className="bimmatch-select-container">
        <div className="labels">

          {!hideLabel && (
            <LabelBox
              required={required}
              label={label}
              infoPlacement={infoPlacement}
              info={info}
              showSettings={showSettings}
              onChangeSettings={onChangeLabelSettings}
              defaultSettings={defaultSettings}
              showAppliedSettings={showAppliedSettings}
              onClickSettings={onClickSettings}
              settingsValue={settingsValue}
              onDelete={onDelete}
            />
          )}
          {(!hideLabel && onClickAddButton) && (
            <div
              onClick={onClickAddButton}
              className="select-head-right"
            >
              <img
                className="add-icon"
                src="/images/icon/plus-darkblack.svg"
                alt="add"
              />
              <p className="head-text">{addButtonText}</p>
            </div>
          )}
        </div>
        <CategorisedSearch
          searchData={options}
          onChangeSearchInput={onChangeInput}
          type={type}
          placeholder={placeholder}
          showClearAll={showClearAll}
          multiple={type !== "category" ? multiple : true}
          onSelectChips={onSelectChips}
          defaultValue={defaultValue}
          searchIcon={searchIcon || ((type === "select" && !multiple) ? "../../images/icon/down-arrow.svg": "")}
          value={value}
          optionsShadow={optionsShadow}
          optionsClassName={optionsClassName}
          {...props}
        />
        {showValidationMessage && <ErrorText message={hasErrorMessage} />}
      </div>
    </div>
  );
};

export default Select;
