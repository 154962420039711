import React from "react";
import Button from "../button";

const Modal = ({
  children,
  title = "Modal Title",
  cancelButtonName = "Cancel",
  confirmButtonName = "Confirm",
  size = "medium",
  onClickCancel,
  onClickConfirm,
  visible = false,
  closable = true,
  cancelable = true,
  withFooter = true,
  onClose,
  type = "content",
  extraMessage,
  confirmButtonDisable,
  cancelButtonDisable,
  cancelButtonLoading,
  confirmButtonLoading,
  suggestUsUrl,
  suggestUsText,
  onClickSuggestUs,
  modalClass = '',
  footerContent
}) => {
  return (
    <div
      className={`bimmatch-modal  ${modalClass} ${size === 'extra-large' ? 'bimmatch-modal-extra-large' : size === 'medium' ? 'bimmatch-modal-medium' : 'bimmatch-modal-large'
        } ${visible ? 'bimmatch-modal-open' : 'bimmatch-modal-close'}`}
    >
      {visible && (
        <>
          <div className='grey-screen'></div>
            <div className='modal-container'>
              <div className='bimmatch-modal-head'>
                <h3 className='modal-title'>{title}</h3>
                {closable && (
                  <img
                    className='close-icon'
                    onClick={onClose && onClose}
                    src='/images/icon/close.svg'
                    alt='close'
                  />
                )}
              </div>
              <div className='bimmatch-modal-body'>
                {type === 'error' && (
                  <>
                    <div className='extra-message'>{extraMessage || 'You have unsaved changes in the page'}</div>
                    <div className='bimmatch-modal-error'>
                      <img
                        className='error-icon'
                        src='/images/icon/emoji-surprise.svg'
                        alt='error'
                      />
                    </div>
                  </>
                )}
                {type === 'content' && children}

                {type === 'surprised' && (
                  <div className='bimmatch-modal-surprised'>
                    <p>
                      {extraMessage}
                    </p>
                    <img src={'/images/icon/emoji-surprise.svg'} alt="emoji-surprise" />
                  </div>
                )}
              </div>
              {
                withFooter && <div className='bimmatch-modal-footer'>
                  {suggestUsText && <div onClick={onClickSuggestUs && onClickSuggestUs} className='suggest-us'> {suggestUsUrl ? <a href={suggestUsUrl}>{suggestUsText}</a> : suggestUsText}</div>}
                  {footerContent && React.isValidElement(footerContent) && footerContent}
                  <div className='modal-button-container'>
                    {cancelable && (
                      <Button
                        onClick={onClickCancel && onClickCancel}
                        name={cancelButtonName}
                        disable={cancelButtonDisable}
                        loading={cancelButtonLoading}
                      />
                    )}
                    <Button
                      onClick={onClickConfirm && onClickConfirm}
                      name={confirmButtonName}
                      type={'secondary'}
                      disable={confirmButtonDisable}
                      loading={confirmButtonLoading}
                    />
                  </div>
                </div>
              }
            </div>
        </>
      )}
    </div>
  );
};

export default Modal;
