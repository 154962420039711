import React, { useCallback } from "react";

const ImageWithFallback = ({ src, fallbackSrc, alt, ...props }) => {

  const onError = useCallback((event) => {
    // Note: can be changed to react state update if this comp needs to rerendered
    Object.assign(event.target, {
        onerror: null,
        src: fallbackSrc || "/images/bom-icons/Generic.svg",
        className: `${event.target.className} fallback-image`,
    });

    event.target.removeAttribute("srcset");
    event.target.style.objectFit = "contain"
    
  }, [fallbackSrc])
  

  return (
    <img className="bimmatch-image"
      src={src}
      alt={alt || "image"}
      onError={onError}
      {...props}
    />
  );
};

export default ImageWithFallback;