import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { deepClone, flatTreeData, combineNames } from '../../utils'
import Chip from '../chip'
import SelectType from './selectType'
import CategoryType from './categoryType'
import TreeType from './treeType'
import NoDataView from '../no-data-view'
import { v4 as uuidv4 } from 'uuid'
import { createPortal } from 'react-dom/cjs/react-dom.production.min'
import { useTrackPosition } from '../../hooks'

const CategorisedSearch = ({
  onChangeSearchInput,
  onSelectChips,
  onClickClearAll,
  showClearAll = false,
  type = 'category',
  focusedPlaceholder = 'Type to search',
  placeholder = 'Search for brands and distributors',
  searchIcon,
  resetSelection = false,
  onReset,
  multiple = true,
  showOptions = false,
  defaultValue,
  searchData=[],
  value,
  suggestUsText='',
  suggestUsUrl,
  onClickSuggestUs,
  defaultOpenTab = 1,
  parentSelectable,
  noDataText,
  optionsVisibleOnSearch = false,
  maxHeight = 300,
  isSelectBox = false,
  optionsShadow = true,
  optionsClassName = '',
  ...props
}) => {
  const searchDataStringify = JSON.stringify(searchData)
  const intitalSelectedData = useMemo(() => (type !== 'category' ? [] : {}), [type])
  const [currentActiveCategoryTab, setCurrentActiveCategoryTab] = useState(isNaN(defaultOpenTab) ? undefined : defaultOpenTab)
  const [totalSelectedData, setTotalSelectedData] = useState(intitalSelectedData)
  const [searchInput, setSearchInput] = useState('')
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [isDropdownFocused, setIsDropdownFocused] = useState(false)
  const [isMouseOverDropdown, setIsMouseOverDropdown] = useState(false)
  const [isMouseOverSearchCancel, setIsMouseOverSearchCancel] = useState(false)
  const [defaultValueSetStatus, setDefaultValueSetStatus] = useState(false)

  // ref
  const optionsContainerRef = useRef(null)
  const searchInputRef = useRef(null)

  // hook
  const searchInputPosition = useTrackPosition(searchInputRef)

  // memo
  const optionsId = useMemo(() => uuidv4(), [])
  const isOptionsVisible = useMemo(() => (!(optionsVisibleOnSearch & isInputFocused) ? (isInputFocused || isDropdownFocused || showOptions) :  searchInput.length), [isDropdownFocused, isInputFocused, optionsVisibleOnSearch, searchInput.length, showOptions]) 

  // flattening search data for all types
  const searchDataFlat = useMemo(() => {
    let finalFlatData = searchData

    if (type === 'tree') {
      finalFlatData = flatTreeData(searchData)
    }

    if (type === 'category') {
      finalFlatData =  searchData.map(section => section.buckets).flat()
    }

    return deepClone(finalFlatData)
  }, [searchData, type])

  // TODO when we will enable to remove chip and parentChip
  // const onClickSearchChip = (categoryId, chipData) => {

  // }

  // for select/multiselect/tree
  const onClickSelectRemoveChip = useCallback(
    chipData => {
      const newState = totalSelectedData.filter(
        data => data.id !== chipData.id
      )
      onSelectChips && onSelectChips(false, chipData, [...newState])
      setTotalSelectedData([...newState])
    },
    [onSelectChips, totalSelectedData]
  )

  const setFilteredSearchDataFromIds = useCallback( filterIds => {
    if(type !== 'category'){
      const defaultValueIds = !multiple ? [filterIds]: filterIds
      const defaultValueObjs = searchDataFlat.filter(option => defaultValueIds.includes(option.id))
      setTotalSelectedData(defaultValueObjs)
    } else {
      
     let defaultValueObj = {}
      searchData.forEach(categoryData => {
       const categoryDataOptions = categoryData?.buckets
       
        if(categoryDataOptions.length){
          const matchingObjsFromOptions = categoryDataOptions.filter(option => { 
            const toInclude = filterIds.includes(option.id)
            return toInclude
          })

          // adding category id each filtered option
          const matchingObjsFromOptionsWithCategoryId = matchingObjsFromOptions.map(option => ({...option, categoryId: categoryData.id}))
          if(matchingObjsFromOptionsWithCategoryId.length) defaultValueObj[categoryData.id] = matchingObjsFromOptionsWithCategoryId
        }
      })
      setTotalSelectedData(defaultValueObj)
    }
  }, [type, multiple, searchData, searchDataFlat])

  //setting up value as selected value
  useEffect(() => {
    if(value && searchData && Object.keys(searchData).length) {
      setFilteredSearchDataFromIds(value)
    }
  }, [value, setFilteredSearchDataFromIds, searchData])

  // setting defaultvalue as selected value
  useEffect(() => {
    if (!value && !defaultValueSetStatus && searchData?.length && defaultValue?.length && !(Object.keys(totalSelectedData)?.length)){ 
      setDefaultValueSetStatus(true)
      setFilteredSearchDataFromIds(defaultValue)
    }
  }, [defaultValue, searchData, totalSelectedData, defaultValueSetStatus, setFilteredSearchDataFromIds, value])

  useEffect(() => {
    if (resetSelection) {
      setTotalSelectedData(intitalSelectedData)
    }
    onReset && onReset()
  }, [resetSelection, intitalSelectedData, onReset])

  // deselecting chips from the selected list inside the search input
  const onClickHeadChipCancel = useCallback(
    (event, categoryId, chipData) => {
     if (event) event.stopPropagation()

      // for type select/multiselect/tree
      if (type !== 'category') {
        onClickSelectRemoveChip(chipData)
        return
      }

      // for type category
      const newData = totalSelectedData[categoryId].filter(
        data => data.id !== chipData.id
      )

      if (newData.length) {
        totalSelectedData[categoryId] = newData
      } else {
        delete totalSelectedData?.[categoryId]
      }

      
      onSelectChips &&
        onSelectChips(
          false,
          { ...chipData, categoryId },
          { ...totalSelectedData }
        )
      setTotalSelectedData({ ...totalSelectedData })
    },
    [onSelectChips, type, onClickSelectRemoveChip, totalSelectedData]
  )

  useEffect(()=>{
    if (Object.keys(totalSelectedData).length) {
      const copyOfSelectedData = deepClone(totalSelectedData)
        const formattedSelectedData = type !== 'category' ? copyOfSelectedData : Object.values(copyOfSelectedData).flat()
        const updatedSearchData = searchDataFlat
        // const updatedSearchDataIds = updatedSearchData.map(data => data.id)
        formattedSelectedData.forEach(selectedChipData => {
          const selectedDataInSearchData = updatedSearchData.find(singleSearchData => singleSearchData.id === selectedChipData.id)
          // if already selected chip data not found in updated search data then removing it from the selected list
          if (!selectedDataInSearchData) {
            onClickHeadChipCancel(false, selectedChipData?.categoryId, selectedChipData)
          } else if (selectedChipData.displayName !== selectedDataInSearchData.displayName){
            // if already selected chip data found in the updated search data then checking if the name is same or not
            // if name changed then updating the selected data list with new updated name
            selectedChipData.displayName = selectedDataInSearchData.displayName
            setTotalSelectedData(copyOfSelectedData)
          }
        })
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalSelectedData, type, searchDataStringify, onClickHeadChipCancel, searchDataFlat])

  // on select of any option for type category
  const onSelectOptionTypeCategory = useCallback(
    (categoryId, chipData) => {
      const newData = totalSelectedData[categoryId] ? [...totalSelectedData[categoryId], { ...chipData, categoryId }] : [{ ...chipData, categoryId }]
      totalSelectedData[categoryId] = newData
      onSelectChips &&
        onSelectChips(
          true,
          { ...chipData, categoryId },
          { ...totalSelectedData }
        )
      setTotalSelectedData({ ...totalSelectedData })
    },
    [onSelectChips, totalSelectedData]
  )

  const onClearAllChips = useCallback((event) => {
    event.stopPropagation()
    setTotalSelectedData(intitalSelectedData)
    onSelectChips && onSelectChips(false, null, intitalSelectedData)
    onClickClearAll && onClickClearAll()
  }, [onClickClearAll, intitalSelectedData, onSelectChips])

  const filterData = useCallback(displayData => {
      if (searchInput.length === 0) return displayData
      const filteredCatgData = displayData.filter(data => {
        return combineNames(data).toLowerCase().startsWith(searchInput.toLocaleLowerCase())
      })
      return filteredCatgData
    }, [searchInput])

  const allSearchChips = Object.values(totalSelectedData).flat() || {}

  const onBlurInput = useCallback(() => {
    if (isMouseOverDropdown) {
      setIsDropdownFocused(true)
    }

    if (isMouseOverSearchCancel) {
      if (searchInput) setSearchInput('')
      onChangeSearchInput && onChangeSearchInput('')
      if (!isInputFocused) setIsInputFocused(true)
      if (searchInputRef) searchInputRef.current.focus()
      setIsMouseOverSearchCancel(false)
      return
    }

    setIsInputFocused(false)
  }, [
    isMouseOverDropdown,
    isMouseOverSearchCancel,
    isInputFocused,
    searchInput,
    onChangeSearchInput
  ])

  const onClickSearchChipContainer = useCallback(e => {
    e.stopPropagation()
    setIsInputFocused(true)
    if (searchInputRef) searchInputRef.current.focus()
  }, [])

  const onChangeSearch = useCallback(e => {
      const value = e?.target?.value
      onChangeSearchInput && onChangeSearchInput(value)
      setSearchInput(value)
    }, [onChangeSearchInput])

  // to clear input field on blur of dropdown
  useEffect(() => {
    if (!(isInputFocused || isDropdownFocused) && allSearchChips.length) {
      if (searchInput) setSearchInput('')
    }
  }, [isInputFocused, searchInput, isDropdownFocused, allSearchChips.length])

  
  const onClickCategoryHead = useCallback((tabIndex) => {
    const activateIndex =  tabIndex + 1
    if (activateIndex !== currentActiveCategoryTab) {
      setCurrentActiveCategoryTab(activateIndex)
    } else {
      setCurrentActiveCategoryTab(null)
    } 
  }, [currentActiveCategoryTab])

  // ------For type (multipleselect & singleselect & tree)
  const onClickChipSelectSearch = useCallback(chipData => {
      // multi select
      if (multiple) {
        const newData = [...totalSelectedData, chipData]
        onSelectChips && onSelectChips(true, chipData, newData)
        setTotalSelectedData(newData)
      } else { // single select
        onSelectChips && onSelectChips(true, chipData)
        setTotalSelectedData([chipData])

        //closing the dropdown after selecting the option (for single select only)
        setIsInputFocused(false)
        setIsDropdownFocused(false)
      }
    }, [onSelectChips, multiple, totalSelectedData])

  const onRemoveChipSelectSearch = useCallback(chipData => {
    // multi select
    if (multiple) {
      onClickSelectRemoveChip(chipData)
    } else { // single select
      onSelectChips && onSelectChips(false, null)
      setTotalSelectedData([])
    }
  }, [onClickSelectRemoveChip, multiple, onSelectChips])

  const onClickFooterButton = useCallback((e) => {
   
    // closing the dropdown first so that it doesn't overlap with anything
    setIsDropdownFocused(false)
    onClickSuggestUs && onClickSuggestUs()
   
  }, [onClickSuggestUs])

  return (
    <div className={`categorised-search ${isOptionsVisible ? 'categorised-dropdown-open' : 'categorised-dropdown-close'}`}>
      <div className='categorised-search-container'>
        <div className='search-with-btn'>
          <input
            className='search-input'
            value={searchInput}
            ref={searchInputRef}
            placeholder={isInputFocused ? focusedPlaceholder : (!(!isInputFocused && !isDropdownFocused && !!allSearchChips.length) && placeholder)}
            onChange={onChangeSearch}
            onFocus={() => setIsInputFocused(true)}
            onBlur={onBlurInput}
          />
          {!isInputFocused && !isDropdownFocused && !!allSearchChips.length && (
            <div
              className={showClearAll  ? 'search-chip-container' : 'search-chip-container search-chip-container-noclear'}
              onClick={onClickSearchChipContainer}
            >
              {multiple
                ? (
                  <>
                    {allSearchChips.map(searchTabData => (
                      <Chip
                        key={searchTabData.id + '-search-chip'}
                        name={searchTabData.displayName}
                        withCross={true}
                        onClickCancel={event =>
                          onClickHeadChipCancel(
                            event,
                            searchTabData.categoryId,
                            searchTabData
                          )
                        }
                        iconSrc={searchTabData.image}
                      />
                    ))}
                  </>
                ) : (
                  <div className='single-select-selected-value'>
                    {allSearchChips[0]?.displayName}
                  </div>
                )}
            </div>
          )}

          <div
            className='btn-container'
            onClick={onClickSearchChipContainer}
          >
            {isInputFocused && searchInput && (
              <img
                className='close-icon'
                alt='close'
                src={'../../images/icon/close.svg'}
                onMouseEnter={() => setIsMouseOverSearchCancel(true)}
                onMouseLeave={() => setIsMouseOverSearchCancel(false)}
              />
            )}
            {showClearAll &&
              !isInputFocused &&
              !isDropdownFocused &&
              !!allSearchChips.length && (
              <div className='clear-btn' onClick={onClearAllChips}>
                 {multiple ? 'Clear All' : 'Clear'} 
              </div>
            )}
            <img
              className='search-btn'
              src={searchIcon || '../../images/icon/search-black.svg'}
              alt='search'
            />
          </div>
        </div>
        {(createPortal(
          <div
            className={`options-container ${isOptionsVisible ? 'options-container-visible' : 'options-container-hidden'} ${isSelectBox ? 'options-container-selectBox' : ''} ${optionsShadow ? 'options-container-shadow' : ''} ${optionsClassName || ''}`}
            tabIndex={1}
            ref={optionsContainerRef}
            onMouseLeave={() => setIsMouseOverDropdown(false)}
            onMouseEnter={() => setIsMouseOverDropdown(true)}
            onBlur={() => setIsDropdownFocused(false)}
            id={optionsId}
            style={{ top: (!isDropdownIntersecting(searchInputPosition, optionsContainerRef) ? (searchInputPosition.top + searchInputPosition.height) : (searchInputPosition.top - (optionsContainerRef?.current?.offsetHeight || 0))), left: searchInputPosition.left, width: searchInputPosition.width }}
          >
            <div className='section-container'>
              <div className='options-main' style={{ maxHeight }}>
                <SelectView 
                  //common props for all types
                  type={type} 
                  filterData={filterData}
                  searchInput={searchInput} 
                  searchData={searchData}
                  totalSelectedData={totalSelectedData}

                  onSelectOption={type !== 'category' ? onClickChipSelectSearch : onSelectOptionTypeCategory}
                  onDeselectOption={type !== 'category' ? onRemoveChipSelectSearch : onClickHeadChipCancel}

                  //props for type tree
                  parentSelectable={parentSelectable}
                  multiple={multiple}

                  //props for type categpry
                  currentActiveCategoryTab={currentActiveCategoryTab} 
                  setCurrentActiveCategoryTab={setCurrentActiveCategoryTab}
                  onClickCategoryHead={onClickCategoryHead}

                  //props for no data
                  noDataText={noDataText}

                  //other props
                  {...props}
                />
              </div>
            </div>
            {!!suggestUsText && <><hr/> <div onClick={onClickFooterButton} className='suggest-us'> {suggestUsUrl ? <a href={suggestUsUrl}>{suggestUsText}</a> :  suggestUsText}</div></>}
          </div>,
        document.body,
        optionsId
        ))}
      </div>
    </div>
  )
}

const isDropdownIntersecting = (inputPosition, dropdownRef) => {
  let isCut = false
  const viewportHeight = window.innerHeight;

  if (dropdownRef.current) {
    const elementEndingAtPostion = inputPosition.top + inputPosition.height + (dropdownRef.current.offsetHeight || getHiddenElementHeight(dropdownRef.current))
    isCut = elementEndingAtPostion > viewportHeight
  }
 
  return isCut
}


function getHiddenElementHeight(element) {
  if (!element) return 0;

  // store the original display style
  const originalDisplay = element.style.display;

  // temporarily show the element
  element.style.display = 'block';

  // measure the height
  const height = element.offsetHeight;

  // revert the display style back to original
  element.style.display = originalDisplay;

  return height;
}

const SelectView = ({ type, noDataText, ...props }) => {
  switch (type) {
    case 'select':
      return (props.filterData(props.searchData)?.length ? <SelectType {...props}/> : <NoDataView noDataText={noDataText}/>)

    case 'tree':
      return <TreeType {...props}/>    
  
    default:
      return <CategoryType {...props}/>  
  }
}


export default CategorisedSearch
